let dot = null;

if (window.dot || window.DOT) {
  dot = window.dot ? window.dot : window.DOT;
  dot = dot.getNewInstance();
  dot.cfg({
    service: 'szngeo',
    host: 'h.seznam.cz',
    canvas: false,
    impress: false,
    mousedown: false,
    load: false,
  });
}

/**
 * @module GeoDot
 */

/** This method do hit depends on allow attribute of localstorage. It can be true or false.
 * Possible types:
 *     0 -> false -> action: 'allow', key: 'isGEOFail'
 *     1 -> true -> action: 'allow', key: 'isGeoRead'
 *     2 -> true after false -> action: 'allow', key: 'isGEOSave'
 * @param {Number} type - Number between [0, 2].
 */
const hit = (type) => {
  // Condition typeof navigator === 'undefined' || !navigator.permissions is true when browser is safari or ie.
  if (dot && (typeof navigator === 'undefined' || !navigator.permissions)) {
    switch (type) {
      case 0:
        dot.hit('event', { d: { action: 'allow', key: 'isGEOFail' } });
        break;
      case 1:
        dot.hit('event', { d: { action: 'allow', key: 'isGeoRead' } });
        break;
      case 2:
        dot.hit('event', { d: { action: 'allow', key: 'isGEOSave' } });
        break;
      default:
        break;
    }
  }
};

export { hit };
