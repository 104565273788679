import { getCookie } from '../Tools/Tools.js';
import { SID_UTILS } from '@iva/utils';

/**
 * This class CookieServer create and send data to the cookie server.
 * @example
 * navigator.geolocation.getCurrentPosition(position => {
 *     const cookie =  new CookieServer(position);
 * }, error => console.log(error));
 */
class CookieServer {
  /**
   * This is constructor of CookieServer. Create src from position obejct.
   * @param {Object} position - Position obejct
   * @external MDN_Position
   * @see https://developer.mozilla.org/en-US/docs/Web/API/Position
   */
  constructor(server, position = null) {
    /** @type {String} */
    this._server = server;
    /** @type {Position} */
    this._position = position;
    /**
     * src in format: /location?lat=${position.coords.latitude}&lon=${position.coords.longitude}
     * &prec=${position.coords.accuracy}&src=geoAPI
     * @type {String} */
    this.src = '';
    this._updateSrc();

    /**
     * Minimun distance when will be sended position to the cookie server.
     * 0.00069 === 50m
     * @type {Number}
     */
    this._MINIMUM_DISTANCE = 0.00069;
    /**
     * Minimun intervel (ms) when will be sended position to the cookie server.
     * @type {Number}
     */
    this._INTERVAL = 300000;
    /**
     * Minimun intervel (ms) when will be sended position to the cookie server.
     * @type {Object}
     * @property {number} longitude - Longtitude of position.
     * @property {number} latitude - Latitude of position.
     */
    this._oldCoords = {
      longitude: 0,
      latitude: 0,
    };
    /** @type {Date} */
    this._sendTime = new Date();

    /** @type {String} */
    this._consent = getCookie('euconsent');

    /** @type {String} */
    this['_consent-v2'] = getCookie('euconsent-v2');

    /** @type {String} */
    this._sid = this._getValidSidFromCookie();
  }

  /**
   * This method send data to the cookie server.
   */
  send() {
    if (this._src !== '' && (this._checkDistance() || this._checkInterval())) {
      const img = new Image();

      img.src = this._src;
      this._sendTime = new Date();
      return true;
    }
    return false;
  }

  /**
   * This method check actual position with old position. If distance is more then minimum distance then return true.
   */
  _checkDistance() {
    const longtitudeDiff = this._position.coords.longitude - this._oldCoords.longitude;
    const latitudeDiff = this._position.coords.latitude - this._oldCoords.latitude;
    const distance = Math.sqrt(longtitudeDiff * longtitudeDiff + latitudeDiff * latitudeDiff);

    this._oldCoords.longitude = this._position.coords.longitude;
    this._oldCoords.latitude = this._position.coords.latitude;

    return distance > this._MINIMUM_DISTANCE;
  }

  /**
   * This method check actual time with old time. If time diff is more then minimum interval then return true.
   */
  _checkInterval() {
    const time = new Date();
    const interval = time.getTime() - this._sendTime.getTime();

    return interval > this._INTERVAL;
  }

  /**
   * Gets stringified identity object including login, sid, udid, euconsent, etc.
   */
  _getIdentityObject() {
    if (window.sznIVA && window.sznIVA.IS) {
      const identities = window.sznIVA.IS.getIdentities(
        { cacheEnough: true, justSettled: false },
        { login: { justSettled: true, cacheEnough: false } },
        false,
        1
      );

      if (identities) {
        return JSON.stringify(window.sznIVA.IS.prepareIdsForBe(identities));
      }
    }

    return '';
  }

  /**
   * Gets valid sid cookie value
   * @returns {string}
   */
  _getValidSidFromCookie() {
    const sid = getCookie('sid');

    if (SID_UTILS.isValidSid(sid)) {
      return sid;
    }
    return '';
  }

  /**
   * Set this._position into the src.
   */
  _updateSrc() {
    if (
      this._position &&
      this._position.coords.latitude &&
      this._position.coords.longitude &&
      this._position.coords.accuracy
    ) {
      this._src =
        `https://${this._server}/location?lat=${this._position.coords.latitude}` +
        `&lon=${this._position.coords.longitude}` +
        `&prec=${this._position.coords.accuracy}` +
        '&src=geoAPI' +
        `${this._consent ? '&euconsent=' + this._consent : ''}` +
        `${this._sid ? '&dsid=' + encodeURIComponent(this._sid) : ''}` +
        `${this['_consent-v2'] ? '&euconsent-v2=' + this['_consent-v2'] : ''}` +
        `&url=${window.location.href}` +
        '&ids=' +
        encodeURIComponent(this._getIdentityObject());
    }
  }

  /**
   * Set new position.
   * @see https://developer.mozilla.org/en-US/docs/Web/API/Position
   * @type {Position}
   */
  set position(value) {
    this._position = value;
    this._updateSrc();
  }

  /**
   * Getter for position.
   * @return {Position}
   */
  get position() {
    return this._position;
  }
}

export default CookieServer;
