import { addListener, removeListener } from '../Tools/Tools.js';

/**
 * This class Listener contains informations about evnet listener.
 * @example
 * const click = new Listener('click', (e) => console.log(e), document);
 * click.start();
 */
class Listener {
  /**
   * This is constructor of Listener.
   * @param {String} type - Type of event ('click', 'resize', ...)
   * @param {function} listener - Callback which will be called after click.
   * @param {HTMLElement} element - HTML element.
   */
  constructor(type, listener, element) {
    /** @type {String} */
    this.type = type;
    /** @type {function} */
    this.listener = listener;
    /** @type {HTMLElement} */
    this.element = element;

    addListener(this.type, this.listener, this.element);
  }

  /**
   * This method removes listener.
   * @example
   * const click = new Listener('click', (e) => console.log(e), document);
   * click.stop();
   */
  stop() {
    removeListener(this.type, this.listener, this.element);
  }
}

export default Listener;
