import { getLanguageFromUrl } from '../Language/language';

/**
 * @module Config
 */

/**
 * Default configuration for the Geo.
 */
const defaultConfig = {
  showDialogs: false,
  supportInterval: 6000,
  language: getLanguageFromUrl(),
};

/**
 * Returns the geo configuration.
 * @returns {Object} geo configuration
 */
export const getConfig = () => {
  return {
    ...defaultConfig,
    ...(window.geoConfig || {}),
  };
};
