import Dialog from '../Dialog/Dialog';

/**
 * This class ExceptionHandler create right dialog which depends on error/warning code and browserName
 * @example
 * const warning = new PositionWarning('Message', 4);
 * const exception = new ExceptionHandler(warning, 'geo.seznam.cz');
 * exception.showDialog();
 */
class ExceptionHandler {
  /**
   * This is constructor of ExceptionHandler. Initialize right dialog.
   * @param {PositionWarning|PositionError} exception - PositionWarning or PositionError.
   * @param {String} server - Server used for image urls. It can be for example www.page.com (//${this._server}/....).
   * @param {String} browser - Name of using browser.
   */
  constructor(exception, server) {
    /** @type {PositionWarning|PositionError} */
    this._exception = exception;
    /**
     * Server for {@link Dialog}
     * @type {String}
     */
    this._server = server;
    /** @type {Dialog} */
    this._dialog = null;

    this._recognize();
  }

  /**
   * This method generate dialog into DOM.
   */
  showDialog() {
    if (this._dialog) {
      this._dialog.generate();
    }
  }

  /**
   * This delete dialog from DOM.
   */
  closeDialog() {
    if (this._dialog) {
      this._dialog.close();
    }
  }

  /**
   * This method initilize right dialog depend on error code.
   */
  _recognize() {
    if (this._exception.code === 1 || this._exception.code === 2) {
      this._dialog = new Dialog(this._server, 'denied');
    } else {
      this._dialog = new Dialog(this._server, 'timeout');
    }
  }
}

export default ExceptionHandler;
