/**
 * This class OperatingSystem detect which OS is using.
 * @example
 * const os = new OperatingSystem();
 * console.log(`name: ${os.name}, v: ${os.version}`);
 */
class OperatingSystem {
  /**
   * This is constructor of OperatingSystem. It get useragent and contains definitions of OSs.
   * @external MDN_NavigatorUserAgent
   * @see https://developer.mozilla.org/en-US/docs/Web/API/Window/navigator
   */
  constructor() {
    /** @type {String} */
    this._userAgent = window.navigator.userAgent;
    /** @type {String} */
    this._name = 'Unknown';
    /** @type {String} */
    this._version = '0';
    /**
     * Array of operating system types definitions
     * @type {Array}
     */
    this._definitions = [
      {
        name: 'Windows Phone',
        regex: /Windows ?Phone( ?(OS ?)?([0-9\._\-]+))?/i,
        notRegex: /(Xbox|Windows CE|Windows Mobile)/i,
      },
      {
        name: 'Windows',
        regex: /Windows XP/i,
        notRegex: /(Xbox|Phone|Windows CE|Windows Mobile)/i,
      },
      {
        name: 'Windows',
        regex: /Windows ?NT ?([0-9\._\-]+)?/i,
        notRegex: /(Xbox|Phone|Windows CE|Windows Mobile)/i,
      },
      {
        name: 'Windows',
        regex: /Windows/i,
        notRegex: /(Xbox|Phone|Windows CE|Windows Mobile)/i,
      },
      {
        name: 'iOS',
        regex: /iPhone ?OS ?([0-9\._\-]+)/i,
        notRegex: null,
      },
      {
        name: 'iOS',
        regex: /(iPhone)|(iPod)|(iPad).*OS ?([0-9\._\-]+)/i,
        notRegex: null,
      },
      {
        name: 'iOS',
        regex: /(iPhone)|(iPod)|(iPad)/i,
        notRegex: null,
      },
      {
        name: 'Mac OS',
        regex: /Intel ?Mac OS X ([0-9\._\-]+)/i,
        notRegex: null,
      },
      {
        name: 'Mac OS',
        regex: /(Intel ?Mac)|(Macintosh)/i,
        notRegex: null,
      },
      {
        name: 'Android',
        regex: /Android( ?[0-9\._\-]+)?/i,
        notRegex: null,
      },
      {
        name: 'Solaris',
        regex: /(Solaris)|(SunOS)/i,
        notRegex: null,
      },
      {
        name: 'BSD',
        regex: /(BSD)/i,
        notRegex: null,
      },
      {
        name: 'Linux',
        regex: /(Linux)|(X11)/i,
        notRegex: null,
      },
    ];
  }

  /**
   * This method iterate {@link this._definitions} and find definition of  os which is using now.
   */
  detect() {
    this._definitions.forEach((operatingSystem) => {
      this._match(operatingSystem.name, operatingSystem.regex, operatingSystem.notRegex);
    });
  }

  /**
   * This method iterate through the regular expressions and trying find match it in user agent.
   * @param {String} name - Name of os.
   * @param {RegExp} regex - Regular which declare os.
   * @param {RegExp} notRegex - Regular expression which is not a part of os.
   */
  _match(name, regex, notRegex = null) {
    let match = false;

    if (notRegex) {
      match = this._userAgent.match(regex) && !this._userAgent.match(notRegex);
    } else {
      match = this._userAgent.match(regex);
    }

    if (match && this.name === 'Unknown') {
      this._name = name;
      const [, version] = match;
      this._version = version;
    }
  }

  /**
   * This method tell you if is used mobile device.
   * @return {Boolean} True if is used mobile device.
   */
  isMobile() {
    return this.name === 'iOS' || this.name === 'Windows Phone' || this.name === 'Android';
  }

  /** @type {String} */
  get name() {
    return this._name;
  }

  /** @type {String} */
  get version() {
    return this._version;
  }
}

export default OperatingSystem;
