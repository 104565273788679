import { startErrorHandling } from '@iva/error-reporter';
import { getConfig } from './Config/config';
import Geo from './Geo/Geo.js';

startErrorHandling({
  endpoint: 'geo-static',
  scriptName: 'geo.js',
});

if (!navigator.geolocation) {
  // eslint-disable-next-line no-console
  console.error('Browser does not support navigator.geolocation!');
} else {
  window.geo = new Geo(getConfig());
}
