/**
 * @module Constants
 */

/**
 * @constant {string} LANGUAGE - Language key.
 */
export const LANGUAGE = 'language';
/**
 * @constant {string} EVENT_LANGUAGE_CHANGE - Language change event.
 */
export const EVENT_LANGUAGE_CHANGE = 'language-change';
