/**
 * Allows to subscribe and publish events across the codebase.
 * Provides global "store" for key value pairs.
 */
export class PubSub {
  static subscribe(eventName, callback) {
    if (!PubSub.events) {
      PubSub.events = {};
    }
    if (PubSub.events[eventName]) {
      PubSub.events[eventName].push(callback);
    } else {
      PubSub.events[eventName] = [callback];
    }
  }

  static publish(eventName, data) {
    if (!PubSub.events) {
      PubSub.events = {};
    }
    if (!PubSub.events[eventName]) {
      return;
    }
    PubSub.events[eventName].forEach((callback) => callback(data));
  }

  static setToStore(key, value) {
    if (!PubSub.store) {
      PubSub.store = {};
    }
    PubSub.store[key] = value;
  }

  static getFromStore(key) {
    if (!PubSub.store) {
      PubSub.store = {};
      return null;
    }
    if (PubSub.store[key]) {
      return PubSub.store[key];
    }
    return null;
  }
}
