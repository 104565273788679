import { omitWww } from '../Tools/Tools';

/**
 * @module Language
 */

/**
 * Available languages.
 */
const lang = {
  cs: 'cs',
  en: 'en',
  uk: 'uk',
};

/**
 * Returns the language from the URL.
 * @returns {string} language
 */
export const getLanguageFromUrl = () => {
  const host = omitWww(window.location.hostname);
  for (const [key, val] of Object.entries(lang)) {
    if (new RegExp(`^${val}.`).test(host)) {
      return key;
    }
  }

  return lang.cs;
};
